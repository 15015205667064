<template>
	<div class="cituroContainer"></div>
	<div id="cituro-widget-container"></div>
</template>

<script>
import PageLayout from '#nk/mixins/page-layout';

export default {
	name: 'NkTemplateCituro',

	mixins: [PageLayout],

	mounted() {
		const script = document.createElement('script');
		script.id = 'cituro-widget-loader';
		script.src = 'https://app.cituro.com/booking-widget';
		script.setAttribute('data-account-number', '6506613');
		script.defer = true;
		document.getElementById('cituro-widget-container').appendChild(script);

		this.executeScript(document.getElementById('cituro-widget-container'));
	},

	methods: {
		executeScript(el) {
			const scripts = el.querySelectorAll('script');
			scripts.forEach(script => {
				if (script.src) {
					const newScript = document.createElement('script');
					newScript.src = script.src;
					document.body.appendChild(newScript);
				}
			});
		},
	},
};
</script>

<style>
.cituroContainer {
	width: 100%;

	button {
		width: 100%;
	}
}
</style>
