import { default as _91_46_46_46route_93J92wbbranyMeta } from "/builds/webcontact/borsdorf/pages/[...route].vue?macro=true";
import { default as indexNzkcA4k2u3Meta } from "/builds/webcontact/borsdorf/pages/index.vue?macro=true";
export default [
  {
    name: "route",
    path: "/:route(.*)*",
    component: () => import("/builds/webcontact/borsdorf/pages/[...route].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/webcontact/borsdorf/pages/index.vue")
  }
]