<template>
	<div class="flex justify-end h-24">
		<img
			data-src="/static-images/layout/borsdorf-wappen.png?w=96"
			alt="Borsdorf Wappen"
			class="h-24 lazyload"
		/>
	</div>
</template>

<script>
import PageLayout from '#nk/mixins/page-layout';

export default {
	name: 'NkPagePortal',

	mixins: [PageLayout],
};
</script>
